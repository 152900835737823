import ShieldIcon from '@mui/icons-material/Shield';
import ArticleIcon from '@mui/icons-material/Article';
import traficsignalIcon from '../assets/resource-panel/trafficsignal.png';
import contraflowIcon from '../assets/resource-panel/contraflow.png';

export default [
  {
    text: 'Get Traffic Alerts',
    url: 'https://public.govdelivery.com/accounts/TXDOT/subscriber/new?qsp=TXDOT_1',
    imgSrc: traficsignalIcon,
    help: 'Email messaging is currently only available for the El Paso area.  Click for more information.',
  },
  {
    text: 'I-35 Waco',
    url: 'https://i35-maps.tti.tamu.edu/',
    Icon: ShieldIcon,
  },
  {
    text: 'Conditions Text Site',
    url: 'http://conditions.drivetexas.org/current/',
    Icon: ArticleIcon,
  },
  {
    text: 'Contraflow Planing',
    url: 'https://contraflow.drivetexas.org/',
    imgSrc: contraflowIcon,
  },
];

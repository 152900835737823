import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import { getFullwidth, getModalName, getOpen, setClosed } from '../../reducers/info';
import IntroModal from './intro-modal';
import FAQmodal from './FAQ';
import PrintModal from './PrintModal';
import InfoWindowModal from './InfoWindowModal';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const getContent = (modalName) => {
  switch (modalName) {
    case 'intro':
      return IntroModal;
    case 'FAQ':
      return FAQmodal;
    case 'printModal':
      return PrintModal;
    case 'InfoWindowModal':
      return InfoWindowModal;
    default:
      return null;
  }
};

const Modal = () => {
  const open = useSelector(getOpen);
  const fullWidth = useSelector(getFullwidth);
  const modalName = useSelector(getModalName);

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setClosed());
  };

  const ModalContent = getContent(modalName);

  return (

    <BootstrapDialog fullWidth={fullWidth} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <ModalContent />
    </BootstrapDialog>

  );
};

export default Modal;

import { useSelector, useDispatch } from 'react-redux';

import FormGroup from '@mui/material/FormGroup';
import { conditions } from '../../../config';
import { toggleCondition, getConditions, getVisable, getIsFuture } from '../../../reducers/conditions';
import { getShowingCurrent } from '../../../reducers/info';
import SidebarToggle from '../SidebarToggle';
import DisabledSection from './DisabledSection';
import FloodingLikelyToggle from './FloodingLikelyToggle';
import { getIsPreview, getPreviewShowing } from '../../../reducers/app';

const skip = new Set(['X', 'Y', 'N', 'L']);
const CurrentConditions = () => {
  const dispatch = useDispatch();
  const conditionStatus = useSelector(getConditions);
  const isFuture = useSelector(getIsFuture);
  const visable = useSelector(getVisable);

  const isPreview = useSelector(getIsPreview);

  const previewShowing = useSelector(getPreviewShowing);

  let showing = useSelector(getShowingCurrent);
  if (isPreview && !isNaN(previewShowing) && previewShowing !== undefined) {
    showing = previewShowing;
  }
  if (isFuture) return null;

  let visableConditions = conditions.filter(({ key }) => !skip.has(key));

  if (visable.length) {
    visableConditions = visableConditions.filter(({ key }) => visable.indexOf(key) > -1);
  }
  const onChange = (key) => (event) => dispatch(toggleCondition(key, event.target.checked));
  return (
    <FormGroup>
      {visableConditions.map(({ id, name, key, imgSrc, help }) => (
        <SidebarToggle
          key={id}
          name={name}
          checked={conditionStatus[key]}
          onChange={onChange(key)}
          imgSrc={imgSrc}
          tooltip={help}
        />
      ))}
      <FloodingLikelyToggle />
      <DisabledSection show={showing} />
    </FormGroup>
  );
};

export default CurrentConditions;

import { getMap } from '../map-google/mapUtils'; // eslint-disable-line
import LayerManager from './ml-layer-manager';
import { conditions } from '../../config';
import * as lineRules from '../rules/line';
import * as pointRules from '../rules/point';
import { setMaplargeContent } from '../../reducers/infowindow';
import { setRefreshData, toggleCondition, toggleFuture } from '../../reducers/conditions';
import { getPreviewDisabled } from '../../reducers/app';
// import { getDeactivated } from '../../reducers/info';

import store from '../../store';

const { REACT_APP_ML_HOST, REACT_APP_SUBDOMAINS } = process.env;

let layers;
const current = {
  lines: {
    table: 'conditionsLine', // '01042016_conditionlinetest',
    rules: lineRules,
    type: 'line',
    zindex: 1,
    maxZoom: 15,
    minZoom: 5,
  },
  points: {
    table: 'conditionsPoint', // '01042016_conditionpointtest',
    rules: pointRules,
    type: 'point',
    zindex: 2,
    maxZoom: 15,
    minZoom: 5,
  },
};
const future = {
  lines: {
    table: 'futureConditionsLine',
    rules: lineRules,
    click() { console.log('lines clicked'); },
    type: 'line',
    zindex: 2,
    maxZoom: 15,
    minZoom: 10,
  },
  points: {
    table: 'futureConditionsPoint',
    rules: pointRules,
    click() { console.log('points clicked'); },
    type: 'point',
    zindex: 1,
    maxZoom: 15,
    minZoom: 5,
  },
};
export const createMaplarge = () => {
  const map = getMap();
  layers = new LayerManager({
    current: conditions,
    future: conditions.map((item) => ({ ...item })),
  }, map, {
    layers: { current, future },
    maplarge: {
      account: 'appgeo',
      host: REACT_APP_ML_HOST,
      refresh: 30,
      subdomains: REACT_APP_SUBDOMAINS,
    },
    onRefreshed(id, data) {
      store.dispatch(setRefreshData(data, id));
      console.log('refreshed', id, data);
    },
  }, (data, latlng) => {
    store.dispatch(setMaplargeContent(latlng, data));
  });
  window.layers = layers;
  layers.zoom = map.getZoom();
  map.addListener('zoom_changed', () => {
    layers.zoom = map.getZoom();
  });
  const state = store.getState();
  // const deactivated = getDeactivated(store.getState());
  if (state.conditions.showFuture) {
    layers.switchTo('future');
    const conditions = state.conditions.future;
    for (const [condition, value] of Object.entries(conditions)) {
      if (!value) {
        layers.toggleLayer(condition, value);
      }
    }
    if (Object.values(state.conditions.current).some((item) => item)) {
      layers.setOther('current', state.conditions.current);
    }
  } else {
    layers.switchTo('current');
    const conditions = state.conditions.current;
    for (const [condition, value] of Object.entries(conditions)) {
      if (!value) {
        layers.toggleLayer(condition, value);
      }
    }
    if (Object.values(state.conditions.future).some((item) => item)) {
      layers.setOther('future', state.conditions.future);
    }
  }
  // layers.resetHiden(deactivated);
  return layers;
};
export const getMaplarge = () => layers;
export const setDeactivated = (deactivated) => {
  const layers = getMaplarge();
  if (!layers) {
    return;
  }

  if (deactivated.length) {
    deactivated.forEach((condition) => {
      store.dispatch(toggleFuture(condition, false));
      store.dispatch(toggleCondition(condition, false));
    });
  }

  const previewDisabled = getPreviewDisabled(store.getState());
  if (previewDisabled?.length) {
    previewDisabled.forEach((condition) => {
      store.dispatch(toggleFuture(condition, false));
      store.dispatch(toggleCondition(condition, false));
    });
  }
  // layers.resetHiden(deactivated);
};

export const updateGroups = (showing) => {
  const layers = getMaplarge();
  if (!layers) {
    return;
  }
  switch (showing) {
    case 2:
      layers.reactiveConditions();
      layers.disableGroups(['future']);
      return;
    case 1:
      layers.deactiveConditions();
      layers.disableGroups(['future']);
      return;
    default:
      layers.reactiveConditions();
      layers.enableGroups(['future']);
  }
};

import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import NearMeIcon from '@mui/icons-material/NearMe';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Stack from '@mui/material/Stack';
import { useDispatch } from 'react-redux';
import { locateUser, openPrintModal, openIntroModal } from '../../reducers/info';
import { useAppbarHeight } from '../../hooks/useAppBarHeight';

const createStyles = ({ appBarHeight }) => ({
  root: {
    zIndex: 3,
    position: 'fixed',
    cursor: 'pointer',
    left: '0.5rem',
    top: appBarHeight === 56 ? '14rem' : '15rem',
  },
});

const Control = () => {
  const dispatch = useDispatch();
  const appBarHeight = useAppbarHeight();
  const styles = createStyles({ appBarHeight });

  return (
    <Paper id="custom-map-controls" component={Stack} direction="column" elevation={3} sx={styles.root}>

      <IconButton onClick={() => dispatch(locateUser())} aria-label="Zoom to current location">
        <NearMeIcon />
      </IconButton>

      <IconButton onClick={() => dispatch(openPrintModal('printModal'))} aria-label="Open print options modal">
        <PrintIcon />
      </IconButton>

      <IconButton onClick={() => dispatch(openIntroModal('intro'))} aria-label="View website information">
        <InfoOutlinedIcon />
      </IconButton>

    </Paper>
  );
};
export default Control;
